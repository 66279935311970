import { User, Withdrawal } from "../../../types";
import TransactionInfo from "./TransactionInfo";

export default function WithdrawalList({ withdrawals, page, setUser }: { withdrawals: Withdrawal[], page?: "withdrawals", setUser?: React.Dispatch<React.SetStateAction<User | null>> }) {
    return <>
        {page === "withdrawals" && <>
            <div className="additional-wrap-of-crm">
                <div className="current-data mt10">
                    <span>Статус:</span>
                    <span className="-yellow-info"> Ожидает вывода или отклонения ( {withdrawals.filter(item => item.status === "pending").length} )</span>
                </div>
            </div>
            <div className="dinamic-content -cmr-users -other-pages-content">
                <div className="list-of-users">
                    {withdrawals.filter(item => item.status === "pending").map(withdrawal => <TransactionInfo transaction={withdrawal} key={withdrawal.id} setUser={setUser} />)}
                </div>
            </div>
        </>}

        <div className="additional-wrap-of-crm">
            <div className="current-data mt10">
                <span>Статус:</span>
                <span className="-red-info">Отклонено ( {withdrawals.filter(item => item.status === "rejected").length} )</span>
            </div>
        </div>
        <div className="dinamic-content -cmr-users -other-pages-content">
            <div className="list-of-users">
                {withdrawals.filter(item => item.status === "rejected").map(withdrawal => <TransactionInfo transaction={withdrawal} key={withdrawal.id} />)}
            </div>
        </div>

        <div className="additional-wrap-of-crm">
            <div className="current-data mt10">
                <span>Статус:</span>
                <span className="-green-info">Выведено ( {withdrawals.filter(item => item.status === "completed").length} )</span>
            </div>
        </div>
        <div className="dinamic-content -cmr-users -other-pages-content">
            <div className="list-of-users">
                {withdrawals.filter(item => item.status === "completed").map(withdrawal => <TransactionInfo transaction={withdrawal} key={withdrawal.id} />)}
            </div>
        </div>
    </>
}