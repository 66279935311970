import { ErrorResponseType, UserSettingsType } from "../types";
import http from "./index";

const getSettings = async (): Promise<{ error: false, settings: UserSettingsType } | ErrorResponseType> => {
    try {
        const { data } = await http.get("/settings");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const SettingsController = {
    getSettings
};

export default SettingsController;