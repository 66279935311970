import { Link, Navigate, useNavigate } from "react-router-dom"
import Footer from "../../components/public/auth/Footer"
import FormContainer from "../../components/public/auth/FormContainer"
import Input from "../../components/public/auth/Input"
import { useState } from "react"
import UsersAuthController from "../../api/auth"
import { useUser } from "../../context/UserContext"

export default function Login() {
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [messages, setMessages] = useState({
        login: "",
        password: "",
        status: "",
    })

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setMessages({
            login: "",
            password: "",
            status: "",
        })

        const response = await UsersAuthController.signIn(login, password);

        if (response.messages) return setMessages(response.messages);

        setUser(response.user);

        navigate("/dashboard", { replace: true });
    };

    if (user) return <Navigate to="/dashboard" replace />;

    return <div className="wallet-inner">
        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="wallet-main-wrap">
                    <FormContainer>
                        <div className="main-inner">
                            <form className="left-part" onSubmit={handleLogin}>
                                <div className="header-for-join">
                                    Login
                                </div>
                                <div className="signature-for-join">
                                    <span>Don't have an account?</span>

                                    <Link to="/register" className="txt-btn">
                                        <span>Registration</span><div className="circle-txt-btn">&gt;</div>
                                    </Link>
                                </div>
                                <div className="inputs-wrap">
                                    <Input label="Email or ID" placeholder="...@mail.com" required={false} type="text" state={login} onChange={setLogin} message={messages.login} />

                                    <Input label="Password" placeholder="*******" required={false} type="password" state={password} onChange={setPassword} message={messages.password} />
                                </div>

                                {messages.status && <div style={{ marginTop: "20px", textAlign: "center" }}>{messages.status}</div>}

                                <button className="btn-s mt24" type="submit">Login</button>
                            </form>
                        </div>
                    </FormContainer>

                </div>

                <Footer />
            </div>
        </div>
    </div>
}