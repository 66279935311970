import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { UserProvider } from "../context/UserContext";

export default function Layout() {
    const [fill, setFill] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFill(false);
        }, 1000);
    }, [])

    return <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Wallet TONUP</title>
            <meta name="viewport" content="width=device-width" />
            <link href="/assets/user.css" rel="stylesheet" />
        </Helmet>

        {fill && <div style={{ height: "100vh", width: "100%", backgroundColor: "#000", position: "fixed", zIndex: "999999", top: 0, left: 0, overflow: "hidden" }}></div>}

        <UserProvider>
            <Outlet />
        </UserProvider>
    </HelmetProvider>
}