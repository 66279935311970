import Header from "../../components/public/Header";
import upcoming_wowfish from "../../assets/img/launchpad/UPCOMING-WOWFISH-IDO-8.webp";
import cpp_DwdSuD1M from "../../assets/img/cpp-DwdSuD1M.svg";
import boom_ido from "../../assets/img/launchpad/BOOM-IDO.webp";
import pig_ido_1 from "../../assets/img/launchpad/PIG-IDO-1.webp";
import tpx_ido_3 from "../../assets/img/launchpad/TPX-IDO-3.webp";
import metaphone_pass_ido_1 from "../../assets/img/launchpad/MetaPhone-Pass-IDO-1.webp";
import upcoming_ido from "../../assets/img/launchpad/UPCOMING-IDO.webp";
import Footer from "../../components/public/Footer";


export default function Home() {
    return <>
        <Header />

        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="sc-esYjtY jCQrFA"></div>
                <div className="sc-tafSX jkWTZv">
                    <div className="sc-fXSgRJ fIonAN">
                        <div className="sc-JrEyx BTYVz">
                            <h1 className="sc-fjvwmM gTJmDa relative">Launchpad <span className="sc-bbSYpP junnoG absolute">TONUP</span></h1>
                        </div>
                        <div className="sc-fBWQee eTKFIf">
                            <form className="ant-form ant-form-horizontal css-emrxyv">

                            </form>
                            <div className="sc-uVXKs bRAKah">
                                <div className="ant-spin ant-spin-spinning css-emrxyv" aria-live="polite" aria-busy="true"><span role="img" aria-label="loading" className="anticon anticon-loading anticon-spin ant-spin-dot" style={{ fontSize: "24px" }}><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></div>
                            </div>
                            <div className="sc-hknPuZ kjXPtT">
                                <div className="sc-imWZod itZori">
                                    <div className="sc-jXbVAB jYGjon projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={upcoming_wowfish} className="sc-dhKdPU jHZOIU" alt="" />
                                        </div>
                                        <div className="sc-koYCI jaihac">
                                            <span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>WowFish</span>
                                        </div>
                                        <div className="sc-kpDprT iXyWom">
                                            <span className="sc-dAlxHm jLBTVW">
                                                <span className="sc-gsFSjX bvrujt">Upcoming</span>
                                                &nbsp;&nbsp;
                                            </span>
                                        </div>

                                        <img src={cpp_DwdSuD1M} className="sc-jlZhRR ixxhdB" alt="" />

                                        <div className="sc-bmzXxz cjWAUt">
                                            <div className="sc-fqkwJk cZwMqX">&lt;WowFish&gt; is an innovative crypto fishing game. In WowFish, you can farm your weapons, enjoy solo game, multiplayer game, world boss game and pvp game. Massive gold coin will be distribute to the community
                                                every day.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-imWZod itZori">
                                    <div className="sc-jXbVAB jYGjon projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={boom_ido} className="sc-dhKdPU jHZOIU" alt="" />
                                        </div>
                                        <div className="sc-koYCI jaihac">
                                            <span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>BOOM UP</span></div>
                                        <div className="sc-kpDprT iXyWom">
                                            <span className="sc-dAlxHm ktFRuu">
                                                <span className="sc-gsFSjX bvrujt">Complete</span>
                                                &nbsp;&nbsp;
                                            </span>
                                        </div>

                                        <img src={cpp_DwdSuD1M} className="sc-jlZhRR ixxhdB" alt="" />

                                        <div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">Stages</label><span className="sc-cPiJYC dUnMsV">community, uper</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">start time</label><span className="sc-cPiJYC dUnMsV">July 11 10:00 AM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">end time</label><span className="sc-cPiJYC dUnMsV">July 12 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">PRICE</label><span className="sc-cPiJYC dUnMsV">1 $BOOM = 0.000167664 $TON</span></div>
                                            <div className="sc-dLNtp iCQRbM relative">
                                                <label className="sc-jEABJj eonIvn">progress</label>
                                                <i className="sc-eDPFhE jyqMqt absolute"></i>
                                                <div className="sc-eldOKa gqmwjS absolute">
                                                    <span className="sc-fPXMhL eoihvi">2800 $TON</span>
                                                    <span className="sc-gFqAYk SLAZj">MIN</span>
                                                </div>
                                                <div className="sc-eldOKa sc-dAbbbq bnEEHs lakmSz absolute">
                                                    <span className="sc-fPXMhL eoihvi">14000 $TON</span>
                                                    <span className="sc-gFqAYk SLAZj">MAX</span>
                                                </div>
                                                <div className="sc-feUYzb eqbyMq relative">
                                                    <div className="sc-fUnNpA ebabgB absolute" ></div>
                                                </div>
                                            </div>
                                            <div className="sc-hzhKNl cdpZZs">
                                                <label className="sc-fHjqbK lgfTDg">Participants</label>
                                                <span className="sc-hmdnzv gYWQum">1008</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-imWZod hCINjr">
                                    <div className="sc-jXbVAB dRmTPY projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={pig_ido_1} className="sc-dhKdPU jHZOIU" alt="" />
                                            <span className="sc-eeDSqt clrLIU">TonUPx</span></div>
                                        <div className="sc-koYCI jaihac">
                                            <span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>Pig of TON</span>
                                        </div>
                                        <div className="sc-kpDprT iXyWom">
                                            <span className="sc-dAlxHm ktFRuu">
                                                <span className="sc-gsFSjX bvrujt">Complete</span>
                                                &nbsp;&nbsp;
                                            </span>
                                        </div>
                                        <div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">Stages</label><span className="sc-cPiJYC dUnMsV">public sale</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">start time</label><span className="sc-cPiJYC dUnMsV">June 20 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">end time</label><span className="sc-cPiJYC dUnMsV">June 22 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">PRICE</label><span className="sc-cPiJYC dUnMsV">1 $PIG = 0.001000000 $TON</span></div>
                                            <div className="sc-dLNtp iCQRbM relative"><label className="sc-jEABJj eonIvn">progress</label><i className="sc-eDPFhE cYwxWp absolute"></i>
                                                <div className="sc-eldOKa dZWgGk absolute"><span className="sc-fPXMhL eoihvi">10000 $TON</span><span className="sc-gFqAYk SLAZj">MIN</span></div>
                                                <div className="sc-eldOKa sc-dAbbbq bnEEHs lakmSz absolute"><span className="sc-fPXMhL eoihvi">30000 $TON</span><span className="sc-gFqAYk SLAZj">MAX</span></div>
                                                <div className="sc-feUYzb eqbyMq relative">
                                                    <div className="sc-fUnNpA dsmmDt absolute"></div>
                                                </div>
                                            </div>
                                            <div className="sc-hzhKNl cdpZZs"><label className="sc-fHjqbK lgfTDg">Participants</label><span className="sc-hmdnzv gYWQum">118</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-imWZod hCINjr">
                                    <div className="sc-jXbVAB dRmTPY projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={tpx_ido_3} className="sc-dhKdPU jHZOIU" alt="" />
                                            <span className="sc-eeDSqt clrLIU">TonUPx</span></div>
                                        <div className="sc-koYCI jaihac"><span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>TypoX AI</span></div>
                                        <div className="sc-kpDprT iXyWom"><span className="sc-dAlxHm ktFRuu"><span className="sc-gsFSjX bvrujt">Complete</span> &nbsp;&nbsp;</span>
                                        </div>
                                        <div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">Stages</label><span className="sc-cPiJYC dUnMsV">white list, public sale</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">start time</label><span className="sc-cPiJYC dUnMsV">May 29 00:00 AM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">end time</label><span className="sc-cPiJYC dUnMsV">May 31 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">PRICE</label><span className="sc-cPiJYC dUnMsV">1 $TPX = 0.078616352 $TON</span></div>
                                            <div className="sc-dLNtp iCQRbM relative"><label className="sc-jEABJj eonIvn">progress</label><i className="sc-eDPFhE jwKfQw absolute"></i>
                                                <div className="sc-eldOKa kNRjvN absolute"><span className="sc-fPXMhL eoihvi">15000 $TON</span><span className="sc-gFqAYk SLAZj">MIN</span></div>
                                                <div className="sc-eldOKa sc-dAbbbq bnEEHs lakmSz absolute"><span className="sc-fPXMhL eoihvi">65000 $TON</span><span className="sc-gFqAYk SLAZj">MAX</span></div>
                                                <div className="sc-feUYzb eqbyMq relative">
                                                    <div className="sc-fUnNpA OXcgc absolute"></div>
                                                </div>
                                            </div>
                                            <div className="sc-hzhKNl cdpZZs"><label className="sc-fHjqbK lgfTDg">Participants</label><span className="sc-hmdnzv gYWQum">483</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-imWZod hCINjr">
                                    <div className="sc-jXbVAB dRmTPY projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={metaphone_pass_ido_1} className="sc-dhKdPU jHZOIU" alt="" />
                                            <span className="sc-eeDSqt clrLIU">TonUPx</span></div>
                                        <div className="sc-koYCI jaihac"><span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>MetaPhone Pass</span></div>
                                        <div className="sc-kpDprT iXyWom"><span className="sc-dAlxHm ktFRuu"><span className="sc-gsFSjX bvrujt">Complete</span> &nbsp;&nbsp;</span>
                                        </div>
                                        <div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">Stages</label><span className="sc-cPiJYC dUnMsV">public sale</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">start time</label><span className="sc-cPiJYC dUnMsV">May 8 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">end time</label><span className="sc-cPiJYC dUnMsV">May 10 12:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">PRICE</label><span className="sc-cPiJYC dUnMsV">1 $MP = 0.008299999 $TON</span></div>
                                            <div className="sc-dLNtp iCQRbM relative"><label className="sc-jEABJj eonIvn">progress</label><i className="sc-eDPFhE bfFjEB absolute"></i>
                                                <div className="sc-eldOKa gfRwCa absolute"><span className="sc-fPXMhL eoihvi">50000 $TON</span><span className="sc-gFqAYk SLAZj">MIN</span></div>
                                                <div className="sc-eldOKa sc-dAbbbq bnEEHs lakmSz absolute"><span className="sc-fPXMhL eoihvi">207500 $TON</span><span className="sc-gFqAYk SLAZj">MAX</span></div>
                                                <div className="sc-feUYzb eqbyMq relative">
                                                    <div className="sc-fUnNpA ebgmif absolute" ></div>
                                                </div>
                                            </div>
                                            <div className="sc-hzhKNl cdpZZs"><label className="sc-fHjqbK lgfTDg">Participants</label><span className="sc-hmdnzv gYWQum">336</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-imWZod hCINjr">
                                    <div className="sc-jXbVAB dRmTPY projectItem">
                                        <div className="sc-kAycRU dnGhfq">
                                            <img src={upcoming_ido} className="sc-dhKdPU jHZOIU" alt="" />
                                            <span className="sc-eeDSqt clrLIU">TonUPx</span>
                                        </div>
                                        <div className="sc-koYCI jaihac"><span className="sc-eqUzNf fjpQlF" style={{ color: "inherit", fontSize: "inherit" }}>DAOlama</span></div>
                                        <div className="sc-kpDprT iXyWom"><span className="sc-dAlxHm ktFRuu"><span className="sc-gsFSjX bvrujt">Complete</span> &nbsp;&nbsp;</span>
                                        </div>
                                        <div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">Stages</label><span className="sc-cPiJYC dUnMsV">public sale</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">start time</label><span className="sc-cPiJYC dUnMsV">April 29 10:00 AM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">end time</label><span className="sc-cPiJYC dUnMsV">May 1 16:00 PM UTC</span></div>
                                            <div className="sc-cwHqhk cYMVkk"><label className="sc-jEABJj eonIvn">PRICE</label><span className="sc-cPiJYC dUnMsV">1 $LLAMA = 0.012000480 $TON</span></div>
                                            <div className="sc-dLNtp iCQRbM relative"><label className="sc-jEABJj eonIvn">progress</label><i className="sc-eDPFhE eteOpP absolute"></i>
                                                <div className="sc-eldOKa dLqBFS absolute"><span className="sc-fPXMhL eoihvi">10000 $TON</span><span className="sc-gFqAYk SLAZj">MIN</span></div>
                                                <div className="sc-eldOKa sc-dAbbbq bnEEHs lakmSz absolute"><span className="sc-fPXMhL eoihvi">36000 $TON</span><span className="sc-gFqAYk SLAZj">MAX</span></div>
                                                <div className="sc-feUYzb eqbyMq relative">
                                                    <div className="sc-fUnNpA diLaoG absolute" ></div>
                                                </div>
                                            </div>
                                            <div className="sc-hzhKNl cdpZZs"><label className="sc-fHjqbK lgfTDg">Participants</label><span className="sc-hmdnzv gYWQum">311</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-iHGNiK gUwhpf">
                                <div className="sc-dtBeHJ bOMsXE">
                                    <div className="ant-statistic css-emrxyv">
                                        <div className="ant-statistic-title">Total Funds Raised</div>
                                        <div className="ant-statistic-content" style={{ textAlign: "center" }}><span className="ant-statistic-content-prefix">$</span><span className="ant-statistic-content-value">3.1</span><span className="ant-statistic-content-suffix">M+</span></div>
                                    </div>
                                </div>
                                <div className="sc-dtBeHJ bOMsXE">
                                    <div className="ant-statistic css-emrxyv">
                                        <div className="ant-statistic-title">UP Tokens Burned</div>
                                        <div className="ant-statistic-content" style={{ textAlign: "center" }}><span className="ant-statistic-content-value">104,314.9</span><span className="ant-statistic-content-suffix">$UP</span></div>
                                    </div>
                                </div>
                                <div className="sc-dtBeHJ bOMsXE">
                                    <div className="ant-statistic css-emrxyv">
                                        <div className="ant-statistic-title">UP Holders</div>
                                        <div className="ant-statistic-content" style={{ textAlign: "center" }}><span className="ant-statistic-content-value">32,564</span></div>
                                    </div>
                                </div>
                                <div className="sc-dtBeHJ bOMsXE">
                                    <div className="ant-statistic css-emrxyv">
                                        <div className="ant-statistic-title">IDO Participants</div>
                                        <div className="ant-statistic-content" style={{ textAlign: "center" }}><span className="ant-statistic-content-value">10</span><span className="ant-statistic-content-suffix">k+</span></div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="sc-dCFGXG iriyBm">Frequently asked questions</h2>
                            <div className="ant-collapse ant-collapse-icon-position-end sc-jxOSYQ bQUuyj collapse css-emrxyv">
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">01</span>What's TON (The Open Network)?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content"><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">The Open Network is a blockchain aiming to build a decentralized and open internet, created by the community using a technology designed by Telegram. For more information about Ton please refer to<a href="https://ton.org/" target="_blank" rel="noreferrer">https://ton.org/</a>.</p></div></div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">02</span>What is TonUP?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">TonUP is an innovative platform built on the TON Blockchain that aims to revolutionize the way projects are launched and supported in the crypto space. It provides a comprehensive ecosystem for token launches, community participation, and project growth. TonUP is on a mission to discover and nurture the most promising projects out there, bringing exciting opportunities to the wider community. With the unwavering support of TON Foundation and Foresight X, they are dedicated to empowering assets with remarkable potential on the TON Blockchain, fostering the growth of a vibrant TON ecosystem. For more information about TonUP Launchpad and $UP Tokenomics, please refer to<a href="https://docs.tonup.io" target="_blank" rel="noreferrer">https://docs.tonup.io</a></p></div></div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">03</span>What is IDO?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">An Initial decentralised Offering (IDO) is a type of public offering in which a cryptocurrency project launches a new token on a launchpad or DEX. This method has gained popularity as it allows anyone to contribute to the initial offering, providing equal opportunities for all.</p></div></div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">04</span>Is it safe to use TonUP?
                                            Any potential risks when using TonUP?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">TonUP strongly prioritizes the security of its platform and the safety of its users. It has in place robust protection measures such as secure transaction environments and constant monitoring of the platform's activities to avert any malicious actions.<br /><br /><strong>When dealing with cryptocurrencies, potential risks can arise from market volatility, technological vulnerabilities, and smart contract risks.</strong>Smart contracts are self-executing contracts with the terms of the agreement directly written into code. They are used widely in the crypto space for transactions and agreements. However, if a smart contract has a flaw or is not written properly, it can be exploited by malicious actors leading to potential risks and losses. This emphasizes the need for rigorous testing and auditing of smart contracts before they are deployed.<br /><br />Therefore, although TonUP takes significant steps to ensure safety, it is important for users to make investments responsibly and with a thorough understanding of these aspects. Knowledge about your investments, paired with understanding the inherent risks in cryptocurrency investments, is critical.</p></div></div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">05</span>What is ToS, and what is
                                            the process for signing ToS terms for IDO?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box">
                                        <p className="sc-kdBRUi hZeEdx">ToS stands for "Terms of Service," which sets out the terms and conditions that users must agree to before participating in an Initial DEX Offering (IDO) on TonUP. The process for signing the ToS terms is quick and straightforward:<br />&nbsp;&nbsp;&nbsp;&nbsp;1. Click "Connect wallet" to link your wallet securely on TonUP official website.<br />&nbsp;&nbsp;&nbsp;&nbsp;2. Complete the ToS by clicking the red person icon next to your wallet address. Once done, you're ready to go!<br /><br />Please ensure you have at least 1 $TON Coin in your wallet to cover the verification gas fee. You could also fill in your Referee's Wallet address here or provide your wallet address to your friends. This might be related to future benefits and incentives.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">06</span>What happens when users
                                            sign ToS? Will gas fees be returned?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">By signing the ToS, users complete the vital verification process required to participate in IDO events on TonUP. To show our appreciation for the community and secure users' spots in upcoming IDOs, TonUP is launching the ToS Airdrop with $UP tokens. When users sign the ToS, the $TON gas fees incurred will be refunded to them in the form of $UP tokens. This ensures that users are reimbursed for their gas expenses and can enjoy the benefits of participating in the IDO.</p></div></div>
                                </div>
                                <div className="ant-collapse-item" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
                                    <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button" >
                                        <div className="ant-collapse-expand-icon"><i className="sc-fhzEvr cWdGHP iconfont icon-arrow-up ant-collapse-arrow"></i></div><span className="ant-collapse-header-text"><label className="sc-lcIQwB jYwdUP"><span className="sc-dcJtft dBLeMy">07</span>What happens after each
                                            IDO in terms of service fees and $UP token?</label>
                                        </span>
                                    </div>
                                    <div className="ant-collapse-content" ><div className="ant-collapse-content-box"><p className="sc-kdBRUi hZeEdx">After each IDO, 50% of the service fees collected will be allocated to the buyback and burning of $UP tokens, effectively reducing the total supply. The remaining 50% will be used to reward participants in the next IDO, providing them with $UP tokens as incentives. This strategic approach highlights TonUP's commitment to empowering the $UP token as the platform token for TonUP.</p></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
            <div role="region" aria-label="Notifications (F8)" style={{ pointerEvents: "none" }}>
                <ol className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:top-0 sm:right-0 sm:bottom-auto sm:flex-col md:max-w-[420px]"></ol>
            </div>
        </div>
    </>
}