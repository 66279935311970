import logo_white from "../../../assets/img/logo_white.svg"
import wallet from "../../../assets/img/wallet.svg"

export default function FormContainer({ children }: { children: React.ReactNode }) {
    return <div className="wallet-inner-block -registration">
        <div className="top-part-nav">
            <div className="flex items-center">
                <img src={logo_white} alt="tonup" className="sc-jlGhtx qTwgn" />
                
                <div className="wallet-logo">
                    <div className="img-wrap">
                        <img src={wallet} alt="" />
                    </div>
                    <span>Wallet</span>
                </div>

            </div>
        </div>

        {children}
    </div>
} 