import { useUser } from "../../context/UserContext";
import useCheckNeededVerif from "../../hooks/useCheckNeededVerif";
import Header from "./Header";
import DepositModal from "./modal/Deposit";
import SendModal from "./modal/Send";
import SettingsModal from "./modal/Settings";
import VerifModal from "./modal/Verif";

export default function Container({ children }: { children: React.ReactNode }) {
    const { user } = useUser();
    const { neededVerif } = useCheckNeededVerif(user);

    return <>
        <SettingsModal />

        <SendModal />

        <DepositModal />

        {neededVerif && <VerifModal />}

        <div className="wallet-inner">
            <Header />

            {children}
        </div>
    </>
};