import { useEffect, useState } from "react";
import SettingsController from "../../api/admin/settings";
import { toast } from "react-toastify";
import Input from "../../components/admin/Input";
import Area from "../../components/admin/Area";

export default function Settings() {
    const [address, setAddress] = useState("");
    const [memo, setMemo] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return toast(response.message);

        setAddress(response.settings.address);
        setMemo(response.settings.memo);
        setErrorMsg(response.settings.errorMsg);
    }

    const submitWalletSettings = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateWalletSettings(address, memo);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    const submitErrorSettings = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateErrorSettings(errorMsg);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    useEffect(() => {
        getSettings();
    }, []);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="dinamic-inner-header mb40">
                <div>
                    <div>⚠️ ВНИМАНИЕ! Лучше всего вносить изменения вне пика активности.</div>
                </div>
            </div>
            <div className="main-header-of-page">
                Настройки
            </div>

            <div className="dinamic-content -cmr-users -other-pages-content ">
                <form className="-profile-setting mt40" onSubmit={submitWalletSettings}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                                Данные кошелька
                            </div>

                            <div className="input-wrap  -valid-input">
                                <Input state={address} setState={setAddress} placeholder="" title="Адрес" />
                            </div>
                            <div className="input-wrap  -valid-input">
                                <Input state={memo} setState={setMemo} placeholder="" title="memo" />

                            </div>
                            <button className="-save-btn mt20" type="submit" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </form>
                <form className="-profile-setting mt40" onSubmit={submitErrorSettings}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="input-wrap -valid-input">
                                <Area title="Стандартная ошибка при выводе" placeholder="Введите текст стандартной ошибки при выводе TON" state={errorMsg} setState={setErrorMsg} />
                            </div>
                            <button className="-save-btn mt20" type="submit" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </section>
}