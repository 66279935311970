import Footer from "../../components/public/Footer";
import Header from "../../components/public/Header";

export default function Terms() {
    return <>
        <Header />

        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="breadcrumb-area breadcarumb-style-1 pb--120 pt--180">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h1 className="title theme-gradient h2 text-center">TonUP Terms of Service</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><div className="rainbow-gradient-circle"></div><div className="rainbow-gradient-circle theme-pink"></div></div>
                <div className="rainbow-elements-area rainbow-section-gap section-terms">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="entry-content">
                                    <p><strong>1. TERMS AND DEFINITIONS</strong></p>
                                    <ul>
                                        In the present Agreement the following terms have the meaning indicated below, unless otherwise is stipulated:<br /><br />
                                        <li>
                                            1.1 Agreement means the present User Agreement.
                                            <br /><br />
                                            1.2 Account means functional part of the System that controls Users Funds and operations conducted with them. 1.3. Anti-Money Laundering Policy means program on prevention of laundering of money derived from criminal activity and financing of terrorism, which constitutes an essential part of the Agreement which text is available at .
                                            <br /><br />
                                            1.3 Cryptocurrency means peer-to-peer digital currency that has no central issuer and is distributed directly between the owners of such currency.
                                            <br /><br />
                                            1.4 Deal means agreement on onerous alienation of rights on Cryptocurrency concluded between the Users of .
                                            <br /><br />
                                            1.5 Deposit means a transaction involving a transfer of Funds to the Account.
                                            <br /><br />
                                            1.6 is Finance LLP, a legal entity registered according to the law of UK, with the registration number OC393265 of the 19th of May, 2014 , with registered address at 110 Bishopsgate, London EC2N 4AD, UK including but not limited by employees, officers, directors and shareholders, affiliated persons of Finance LLP. Depending on the context also may mean services, products, web-sites, content and other materials, provided by .
                                            <br /><br />
                                            1.7 Privacy Policy means rules of collection, storage, distribution and protection of personal data that the gets from the Users and that is an essential part of the Agreement which text is available at /en/docs/privacy_policy/.
                                            <br /><br />
                                            1.8 Privacy Policy means rules of collection, storage, distribution and protection of personal data that the gets from the Users and that is an essential part of the Agreement which text is available at /en/docs/privacy_policy/.
                                            <br /><br />
                                            1.9 User means ?apable under personal law natural person or business entity formatted in the appropriate legal form according to local legislation that use the Site and/or the System and has concluded the corresponding Agreement with .
                                            <br /><br />
                                            1.10 User Account Data means User Data necessary to access and use the System login, password, email and other data indicated during the registration process as well as after it.
                                            <br /><br />
                                            1.11 Withdrawal means a transaction involving a transfer of Funds from the Users Account.
                                        </li>

                                    </ul>
                                    <p><strong>2. SUBJECT OF THE AGREEMENT</strong></p>
                                    <ul>
                                        <li>
                                            2.1. Under the Agreement renders to the User the following services:
                                            grants access to the System in order to carry out Deals;
                                            grants access to the Account within the System;
                                            provides information necessary for the use of the System and carrying out Deals.
                                            <br /><br />
                                            2.2 Depending on the Users place of residence, the User may not be able to use all the functions of the Site. It is the Users responsibility to follow those rules and laws in his/her place of residence and/or place from which the User accesses this Site.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            3.1 Essential parts of the present Agreement are sections dedicated to Privacy policy, Anti-Money Laundering Policy, and the Fees.
                                            <br /><br />
                                            3.2 In order to get access to the System, the User should complete the registration process. After that the User will get access to the Personal Cabinet created on the basis of the User Account Data.
                                            <br /><br />
                                            3.3 The User is responsible for setting up a password during the registration process. The User can change the password any time after the registration process is completed. The User commits to safeguard password and User Account Data from the access of third parties and is the one to determine the best way to save this data and take steps to prevent it from being discredited. In case of loss or discrediting the password and/or the User Account Data as well as in cases when third parties illegally obtain access to the User???s Personal Cabinet, the User has to inform the about these circumstances by contacting the technical support team. Until receives the corresponding message, all the actions made from the User Personal Cabinet will be considered as made by the proper User as the holder of the Account.
                                            <br /><br />
                                            3.3.1 If the reward pool is zero, then all subsequent transactions fall into the block fork. And require a re-transaction of the full transaction amount.
                                            <br /><br />
                                            3.4 You can only withdraw funds to an external address that is registered and verified with your account. To verify your (external) address with your account, you need to make a deposit from that address. The deposit amount is equal to 3000 STEM. The deposited amount will be added to your current balance and will be available for withdrawal at any time immediately.
                                            <br /><br />
                                            3.5 Insult and threaten other users and/or staff. This is considered as a direct violation of the rules of chat use and may result in a permanent ban of the User; – use any forms of obscene language;
                                            make statements stirring up to international dissention, containing violence propaganda or offending religious feelings of other Users;
                                            send any kind of external links;
                                            text messages in capital letters only;
                                            repeatedly text the same information or constantly repeat the same phrase;
                                            offer to buy or sell the Funds;
                                            advertise products or services of other companies;
                                            flood, spam and discuss topics that are not related to cryptocurrency or the exchange;
                                            providing false or misleading information about exchange.
                                        </li>
                                    </ul>
                                    <p><strong>4. THE ACCOUNT</strong></p>
                                    <ul>
                                        <li>
                                            4.1 If the bounty pool is empty, all subsequent transactions automatically fall into the fork of the block. For processing it is necessary to make a repeated transaction on the alias with the amount not less than 100% of the fork amount.
                                            <br /><br />
                                            4.2 The indicates in the Personal Cabinet the information necessary for the replenishment of the Account and for the withdrawal of Funds from the Account.
                                            <br /><br />
                                            4.3 The Account is considered to be replenished when the corresponding amount of money enters the current account of or when a corresponding message appears in the wallet about receiving not less than 6 (six) confirmations.
                                            <br /><br />
                                            4.4 The commits to receive Cryptocurrency items from Users, keep them, keep a record of them, execute transactions and provide them to the the Users upon their request.
                                            <br /><br />
                                            4.5 The keeps a record of the funds transferred to the Account and does not keep Users money. Money transfers during the Account replenishment as well while executing Deals between the Users are carried out by third parties (aggregators, payment institutions and other facilitators). The is not liable for actions of these third parties.
                                            <br /><br />
                                            4.6 All Account operations are carried out at Users request. The does not accept orders on carrying out any operation with the User Account from third persons except otherwise is stipulated by the Agreement or other agreements between the Parties.
                                            <br /><br />
                                            4.7 All the Funds placed on the Account belong to the User. The cannot block Funds on the Account without the order emitted by the User as well as can write-off Funds only in cases stipulated by the Agreement.
                                            <br /><br />
                                            4.8 To withdraw Funds from the Account, the User has to fill the corresponding form in their Personal Cabinet. The User will get a message to the email address indicated during the registration process asking to confirm or to deny the withdrawal operation. In case the User denies or does not confirm the withdrawal, the Funds will remain in the Account, but it will happens only after User fulfill all the conditions, which is written in this Agreement.
                                            <br /><br />
                                            4.9 The System sets some restrictions for Funds withdrawals and the User cannot instigate withdraw Funds during 3 (three) days after changing User Account Data, which includes changing or restoring password, and also changing authorization method.
                                            <br /><br />
                                            4.10 In case if the User discovers suspicious transaction activity, including but not limited to unknown deposits and withdrawals, on his/her Account that was not initiated by the User, the User shall immediately notify of this fact and follow the instructions sent by . Otherwise, reserves the right to freeze the Account until the end of investigation.
                                            <br /><br />
                                            4.11 If User want to buy premium account , he should be noticed, that we take 2.5% fee from that deposit, which is maden for purchasing a premium status for his account.
                                            <br /><br />
                                            4.12 If registration term of User’s account is less then 1 month, and he received more than 500 TON – he should buy VIP account. Until he make this purchasing his account should be limited in withdrawing. Usual price of VIP account is 500 TON or the same value in other currencies.
                                            <br /><br />
                                            4.13 Creating multi-account is forbidden. One User – one account. In case if our system identify multi-account, account with less balance will be banned. User should pay the penalty for creating multi-account. The amount of this penalty is 1000 TON.
                                            <br /><br />
                                            4.14 You agree to pay the internal Payward fee at additional 1% (all deposits), 1% (all the transfers), 1% (all insurances if required).
                                            <br /><br />
                                            4.15 You can withdraw your funds only on external address, which is registered and verified with your account. To verify your (external) address with your account, you need to make a deposit from this address. Deposited amount will be added to your current balance amount and will be available for withdrawal at any time immediately. More information you will find on Deposit area in your profile.Or contact support
                                            <br /><br />
                                            4.16 If a user’s transaction is caught in a Mainnet blockchain fork, the user is required to re-transact. After the re-transaction, the user receives two transactions.

                                        </li>

                                    </ul>
                                    <p><strong>5. DETECTION AND PREVENTION OF ILLEGAL SITE AND SYSTEM USE</strong></p>
                                    <ul>
                                        <li>
                                            5.1 The User is prohibited to conduct Deals omitting the formal procedure of placing Orders by means of using personal messages and other options of the Site and/or the System. In these cases a message ceases to be private and becomes available for moderators and the administration if one of chat users complaints about it.
                                            <br /><br />
                                            5.2 The User is prohibited to use the Site and/or the System in any other way than those stipulated in the Agreement; in particular, the User is not allowed to advertise products on the Site in case the Parties have not entered an additional specific agreement.
                                            <br /><br />
                                            5.3 The User is prohibited to use the Site and/or the System with unlawful aims including laundering of money derived from criminal activity, financing of terrorism and involvement in extremist activity.
                                            <br /><br />
                                            5.4 The User is obliged to go through the identification process established by the System or third parties (aggregators, credit or other organs) during the Deal-making process as well as fulfill the requirements stipulated by UK law in the field of counteraction of laundering (legitimization) proceeds of crime.
                                        </li>
                                    </ul>
                                    <p><strong>6. RISK WARNING</strong></p>
                                    <ul>
                                        <li>
                                            6.1 The User guarantees that he/she understands general principles of work with Cryptocurrencies and is aware of Cryptocurrency of the following characteristics affecting its value and risks related to it:
                                            <br /><br />
                                            Price volatility: The value of cryptocurrencies is extremely volatile. They are vulnerable to sharp changes in price due to unexpected events or changes in market sentiment.
                                            <br /><br />
                                            Charges and funding costs: Charges and Fees can include the spread, funding charges, and commissions.
                                            Price transparency: When compared with fiat currencies, there can be more significant variations in the pricing of cryptocurrencies used to determine the value of Users position. There is a greater risk that the User will not receive a fair and accurate price for the underlying cryptocurrency when trading.
                                            <br /><br />
                                            6.2 The User guarantees that he/she has a right to execute transactions with Cryptocurrency and possesses a full legal capacity.
                                            <br /><br />
                                            6.3 The User understands that the in no way influences the Exchange Rate set by the Users, does not give recommendations on Exchange Rate determination and does not forecast the rates. The User is responsible for all economic risks related to choosing particular Exchange Rate. The does not guarantee that the Deal will be finally closed and that the conditions of this Deal will be of the Users benefit.
                                            <br /><br />
                                            6.4 The User understands that the in no way bears relation to deal conduction and/or transactions related to Cryptocurrency. Correspondingly the does not assume the responsibility to guarantee terms and/or possibilities of carrying out this or that operation and/or transaction related to Cryptocurrency, which are necessary for Deal making.
                                            <br /><br />
                                            6.5 The User understands that all the operations with Cryptocurrency have irreversible character and that Funds acquired during the Deal can be returned only on the basis of additional specific agreement with other User.
                                        </li>

                                    </ul>
                                    <p><strong>7. INTELLECTUAL PROPERTY AND RESTRICTIONS ON SITE AND SYSTEM USE</strong></p>
                                    <ul>
                                        <li>
                                            7.1 The Site and the System contain fruits of intellectual labor that belong to the , affiliated persons and other related parties, sponsors, partners, representatives, all the parties acting on behalf of the and other third parties.
                                            <br /><br />
                                            7.2 By using the Site and the System the User acknowledges and agrees that all Site content and the structure of Site content are protected by copyright, trademark and other rights concerning the results of intellectual activity, and that the abovementioned rights are authentic and are protected in their every form, in all carriers and regarding all existing and created later technologies. No rights as well as no Site nor System content are committed to the User as a result of Site and System use or on the conclusion of the Agreement.
                                            <br /><br />
                                            7.3 To avoid any misunderstanding the User is prohibited to:
                                            <br /><br />
                                            copy and/or diffuse any items of intellectual property published on the Site and/or in the System except when this function is clearly contemplated by the Site and/or the System;
                                            copy or use in any other way the program part of the Site and/or the System as well as its design;
                                            diffuse on the Site and/or in the System personal data of third persons without their permission;
                                            change in any way the program part of the Site and/or the System, take any actions aimed at changing the functionality and operability of the Site and/or the System;
                                            use insults or any words violating rights and liberties of third persons as a login (nickname, alias) during the registration.
                                        </li>

                                    </ul>
                                    <p><strong>8. RESPONSIBILITIES OF THE PARTIES</strong></p>
                                    <ul>
                                        <li>
                                            8.1. In case the User violates the conditions of the present Agreement, the legislation of the UK, morality norms or in case he/she conspires with another User to violate the conditions of the Agreement, the will have the right to block or delete the Users Personal Cabinet, prohibit or restrict their access to certain or all functions of the System using his/her Personal Cabinet.
                                            <br /><br />
                                            8.2 The is not responsible for the operability of the Site and/or the System and does not guarantee its continuous operation. The does not guarantee the safety of the information published on the Site and/or in the System as well as does not guarantee the possibility of continuous access to the information about Orders and closed Deals, the possibility of Order placement and of making Deals.
                                            <br /><br />
                                            8.3 The User uses the Site and the System in its original form at his/her own risk. The does not guarantee the achievement of any results by the User owing to the Site and/or System use.

                                        </li>
                                    </ul>
                                    <p><strong>9. SPECIAL CONDITIONS</strong></p>
                                    <ul>
                                        <li>
                                            9.1 The Site and the System can contain external links to other sites in the Internet network (third parties sites). The abovementioned third parties? sites and their content are not checked by criteria of conformity with certain requirements (authenticity, completeness, legality, etc.). The will not be liable for the information and the materials published on third parties? sites, which the User can access via the Site and/or the System as well as does not assume any responsibility for expressed opinions or statements, advertising materials, the accessibility and possible consequences of use of third parties? sites.
                                            <br /><br />
                                            9.2 Given the specifics of transactions, does not implement a chargeback policy. However, has developed and implemented a detailed Cross-Chain Recovery Policy, which provides a detailed guideline in the cases where an erroneous deposit of one cryptocurrency into wallet address instead of another cryptocurrency is made by the User. The recovery of Cross-chain deposits is an inherently dangerous and a very time consuming process. Not all deposits can be recovered and dependent on which currency has been mistakenly sent to which address can influence difficulty, time and security risk involved.
                                            <br /><br />
                                            9.3 Making a transaction, the User agrees and carries the entire risk on the transaction. To cannot be made any claims for the cancellation of the Transaction. does not accept or process applications from anyone for the return and / or cancellation of an operation. The Exchange take 1% fee from ever deposit.
                                            <br /><br />
                                            9.4 Verification of cryptocurrencies is carried out in order to exclude the possibility of illegal use of funds contained in the account of the user of our site.
                                            <br /><br />
                                            9.5 Verification of crypto-wallets is carried out in the following order:
                                            <br /><br />
                                            Creation of a deposit application;
                                            Witdrawing the verified cryptocurrency wallet in the declared amount according to the commission terms established by the Parties.
                                            <br /><br />
                                            9.6 If the User has no activity on account for 20 days, no withdraw, no trading, no deposit, the activation is nessesary
                                            <br /><br />
                                            9.7 User who is a citizen of the Russian Federation agrees that due to the aggressive policy of this State and the imposition of appropriate international sanctions on it and the Russian Federation’s ranking as a high-risk country for illegal financial transactions, he will have to make an insurance contribution to the account provided by the exchange. The same rule is introduced for User-s from another high-resked countries for illegal financial transactions.
                                            <br /><br />
                                            9.7.1 Also countries (Russia, Belarus, Ukraine, Kazakhstan, Iraq, Iran, Iraq, Pakistan) fall under rule 9.7.
                                            <br /><br />
                                            9.8 The insurance payment will be returned to the User’s account within 24 hour if there is no data on the commission of any actions that violate the terms of this Agreement, the norms of international law, the customs of honest financial and information turnover.
                                            <br /><br />
                                            9.9 User agrees that it will not be able to carry out over-the-counter.
                                            <br /><br />
                                            9.9.1 Users, who didn’t make a deposit, but received their funds from another account, should buy premium account in case, if they received more then 100000 IOST from one User. This rule applies to those, who is registered earlier than 1 month. After one month passed, if premium account isn’t connected, all new funds, if it is received from another account, will be available for withdrawing, but those funds, which was received during time, when term of registration was less, than 1 month, will be frozen until User don’t connect premium account or make an insurance payment.
                                            <br /><br />
                                            9.9.2 Deposit for purchasing premium status and insurance payment should be done by one payment.
                                        </li>
                                    </ul>
                                    <p><strong>10. LIMITATION OF LIABILITY</strong></p>
                                    <ul>
                                        <li>
                                            10.1 To the extent permitted by law neither nor ‘ affiliates, and ‘ suppliers and distributors will be responsible for lost profits, revenues, financial losses, indirect, special, consequential, exemplary damages.
                                            <br /><br />
                                            10.2 To the extent permitted by law the total amount of liability, its affiliates, and ‘S suppliers and distributors is limited to the amount you paid to for all Services provided during 3 (three) months prior to the event giving rise to the liability.
                                            <br /><br />
                                            10.3 Its affiliates, and ‘S suppliers and distributors will not be liable for any expense, loss or damage that is not reasonably foreseeable.
                                        </li>
                                    </ul>
                                    <p><strong>11. INDEMNIFICATION</strong></p>
                                    <ul>
                                        <li>
                                            11.1 The User agrees to defend, indemnify and hold , its affiliates and their respective employees, officers, directors and stockholders harmless from and against any and all damages claimed by a third party as a result of actions by User in violation of this Agreement. The User agrees to defend, indemnify and hold , its affiliates and their respective employees, officers, directors and stockholders harmless from and against any and all damages for damages to property, bodily injury, death, or other injuries arising from the negligence or misconduct of the User or any person for whom User is legally responsible.
                                            <br /><br />
                                            11.2 The party seeking indemnification in any case shall promptly give written notice to the other of the claim for which indemnification is sought and shall cooperate with the other party in the defense of such an action or suit. The failure to give or delay in giving any such notice shall not limit the indemnifying party’s rights hereunder except to the extent it is prejudiced thereby. The indemnifying party shall have the right, at its expense, to direct any such legal proceeding and the negotiation and settlement of any such claim or demand. The indemnifying party shall have no liability for any settlement made without its consent or for any fees or expenses incurred by the other party after the indemnifying party begins directing the legal proceeding.
                                        </li>
                                    </ul>
                                    <p><strong>12. DISPUTE SETTLEMENT</strong></p>
                                    <ul>
                                        <li>
                                            12.1 The Parties will tend to solve all disputes, differences and claims that can arise out of the execution, termination or cancellation of the Agreement by means of negotiations. The Party that has some claims should send a notification to the other Party describing the arisen claims and/or differences.
                                            <br /><br />
                                            12.2 The Party in default on its obligations hereunder shall promptly cure the breach and take due measures to eliminate the consequences. Any dispute arising out of or in connection with this Agreement (including a dispute regarding the existence, validity or termination of this Agreement or the consequences of its nullity) shall be referred to and finally resolved by arbitration under the Arbitration Rules of the London Court of International Arbitration.
                                            <br /><br />
                                            12.3 If any dispute occurs as to the performance or interpretation of the present Agreement that cannot be resolved amicably or in arbitration the Company is entitled to demand the dispute be settled by the Court in location where the resides.
                                        </li>
                                    </ul>
                                    <p><strong>13. AMENDMENTS OF THE AGREEMENT</strong></p>
                                    <ul>
                                        <li>
                                            13.1 The Service has the right to unilaterally change the terms of the Agreement, Privacy Policy, Anti-Money Laundering Policy and the Fees. The changes take effect 3 (three) days after the moment the new version of corresponding documents is published.
                                            <br /><br />
                                            13.2 At each successive visit to the Site and before starting the use of the Personal Cabinet the User commits to become familiar with the new version of the Agreement, Privacy Policy, Anti-Money Laundering Policy and the Fees. If the User continues to use the Site and the System it will mean that he/she agrees with new versions of the corresponding documents.
                                            <br /><br />
                                            13.3 If the User does not accept new terms of the Agreement, Privacy Policy, Anti-Money Laundering Policy and the Fees, he/she should stop using the Site having closed the Deals with other Users.
                                        </li>
                                    </ul>
                                    <p><strong>14. CONCLUDING PROVISIONS</strong></p>
                                    <ul>
                                        <li>
                                            14.1 With the exception to the cases defined by the Agreement and the current legislation, all the notifications, messages and documents related to the fulfillment of obligations arised out of the Agreement should be sent to and are considered as received by the Parties if they have been sent via email from the authorized address of one Party to the authorized address of the other Party. An authorized address can be:
                                            <br /><br />
                                            for the User: the email address indicated in the User Personal Cabinet;
                                            for the :
                                            <br /><br />
                                            14.2 The and all legal relations concerning it are regulated by the legislation of the UK without taking into account its conflict rules. All the arisen disputes are solved according to the legislation of the UK.
                                            <br /><br />
                                            14.3 If any clause of the Agreement is found void and unenforceable by a court decision, it will not affect the validity of other clauses of the Agreement, which shall remain valid and enforceable.
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
            <div role="region" aria-label="Notifications (F8)" style={{ pointerEvents: "none" }}>
                <ol className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:top-0 sm:right-0 sm:bottom-auto sm:flex-col md:max-w-[420px]"></ol>
            </div>
        </div>
    </>
}