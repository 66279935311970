import { useEffect, useState } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "coin-stats-chart-widget": any; // Use `any` if you don't have specific type info
        }
    }
}

export default function Widget() {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.coinstats.app/widgets/coin-chart-widget.js";
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.head.appendChild(script);

        return () => {
            // Cleanup script if the component unmounts
            document.head.removeChild(script);
        };
    }, []);

    if (!scriptLoaded) {
        return <div>Loading...</div>; // Optional loading indicator
    }

    return (
        <coin-stats-chart-widget
            type="large"
            coin-id="the-open-network"
            width="100%"
            chart-height="300"
            currency="USD"
            locale="en"
            bg-color="transparent"
            text-color="#FFFFFF"
            status-up-color="#74D492"
            status-down-color="#FE4747"
            buttons-color="#1C1B1B"
            chart-color="#2388FF"
            chart-gradient-from="rgba(255,255,255,0.07)"
            chart-gradient-to="rgba(0,0,0,0)"
            chart-label-background="#000000"
            candle-grids-color="rgba(255,255,255,0.1)"
            border-color="rgba(255,255,255,0.15)"
            font="Inter"
            btc-color="#6DD400"
            eth-color="#67B5FF"
        ></coin-stats-chart-widget>
    );
}