import { ErrorResponseType, GetSettingsResponseType, UpdateSettingsResponseType } from "../../types";
import http from "./index";

const updateWalletSettings = async (address: string, memo: string): Promise<UpdateSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/settings/wallet`, {
            address,
            memo
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const updateErrorSettings = async (errorMsg: string): Promise<UpdateSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/settings/error`, {
            errorMsg,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const getSettings = async (): Promise<GetSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/settings`);

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const SettingsController = {
    updateWalletSettings,
    updateErrorSettings,
    getSettings,
}

export default SettingsController;