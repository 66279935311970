import http from ".";
import { DepositErrorType, DepositResponseType, SendTransactionErrorType, SendTransactionResponseType } from "../types";

const send = async (userId: number, address: string, memo: string, amount: string): Promise<SendTransactionResponseType | SendTransactionErrorType> => {
    try {
        const { data } = await http.post(`/transactions/${userId}`, { address, memo, amount });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const deposit = async (userId: number, amount: string): Promise<DepositResponseType | DepositErrorType> => {
    try {
        const { data } = await http.post(`/transactions/${userId}/deposit`, { amount });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const TransactionsController = {
    send,
    deposit,
}

export default TransactionsController;