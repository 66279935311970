import { Deposit, Withdrawal } from "../../types";

export default function Transactions({ transactions }: { transactions: (Deposit | Withdrawal)[] }) {
    return <>
        <div className="header-of-block">
            Transaction history:
        </div>
        <div className="transaction-history-list">
            {transactions.length === 0 && <div className="signature">No transactions</div>}

            {transactions.map((transaction, index) => (
                <div className="transaction" key={index}>
                    <div className="transaction-inner">
                        <div className="left-part-transaction">
                            <div className="icon-transaction -send"></div>
                            <div className="wrap-left-transaction">
                                <span className="signature">
                                    {transaction.type === "withdrawal" ? "Send" : "Deposit"} {transaction.status === "pending" ? <span>Pending...</span> : ""}
                                </span>
                                <span className="adres">
                                    <span>to</span> {transaction.address}
                                </span>
                            </div>
                        </div>
                        <div className="right-part-transaction">
                            <span className="signature">
                                {new Date(transaction.created_at).toLocaleDateString("ru-RU")}
                            </span>
                            <div className={`value-amoult ${transaction.status ? "" : "-green"}`}>
                                {transaction.type === "withdrawal" ? "-" : "+"} {transaction.amount} <span>TON</span>
                            </div>
                        </div>
                    </div>
                    {transaction.status === "rejected" && <div className="transaction-error">
                        <div className="signature -error">
                            Alert!
                        </div>
                        <div className="value-error">
                            {transaction.type === "withdrawal" ? transaction.msg : "Отклонено"}
                        </div>
                    </div>}
                </div>
            ))}
        </div>
    </>
}