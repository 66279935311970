import http from ".";
import { ErrorResponseType, UserResponseType } from "../../types";

const addDeposit = async (id: number, date: string, amount: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/admin/users/${id}/deposit`, { date, amount });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const handleFakeDeposit = async (id: number): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/deposit/${id}/fake`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const handlePendingDepositToCompleted = async (id: number): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/deposit/${id}/`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const DepositsController = {
    addDeposit,
    handleFakeDeposit,
    handlePendingDepositToCompleted
}

export default DepositsController;