import Footer from "../../components/public/Footer";
import Header from "../../components/public/Header";
import stakebanner_NJBiYT2x from "../../assets/img/stakebanner-NJBiYT2x.png";

export default function Stacking() {
    return <>
        <Header />

        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="sc-fsvrPk VNRFK">
                    <div className="sc-jOnqpJ kdPqZo">
                        <h1 className="sc-kFWkGL gCmVjN">Staking</h1>
                        <div className="sc-bkENKe hcxuCE">
                            <img src={stakebanner_NJBiYT2x} className="sc-dhFVuh kgzDqj" alt="" />
                        </div>
                        <div className="sc-gEkHwe gvRKMz">
                            <div className="sc-uVXKs bRAKah">
                                <div className="ant-spin ant-spin-spinning css-emrxyv" aria-live="polite" aria-busy="true"><span role="img" aria-label="loading" className="anticon anticon-loading anticon-spin ant-spin-dot" style={{ fontSize: "24px" }}><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></div>
                            </div>

                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-bOhtQk cWunsR">
                                    <img src="data:image/svg+xml,%3csvg%20width='18'%20height='18'%20viewBox='0%200%2018%2018'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M3.65819%202.25206H14.3489C14.5889%202.25206%2014.8143%202.36687%2014.9555%202.56093L17.8225%206.50323C17.9278%206.64789%2017.9162%206.84671%2017.7949%206.97814L9.27908%2016.2035C9.1386%2016.3557%208.90138%2016.3652%208.7492%2016.2247C8.74185%2016.2179%208.7348%2016.2109%208.72797%2016.2035L0.212244%206.97814C0.0909197%206.84671%200.0793127%206.64789%200.18452%206.50323L3.05165%202.56093C3.19278%202.36687%203.41825%202.25206%203.65819%202.25206Z'%20fill='white'/%3e%3c/svg%3e" className="sc-fFlofg cnCjdX" alt="" />Whitelist
                                    <img src="data:image/svg+xml,%3csvg%20width='16'%20height='16'%20viewBox='0%200%2016%2016'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cg%20opacity='0.8'%3e%3cpath%20d='M8.00004%2014.6667C4.31814%2014.6667%201.33337%2011.6819%201.33337%208C1.33337%204.3181%204.31814%201.33333%208.00004%201.33333C11.6819%201.33333%2014.6667%204.3181%2014.6667%208C14.6667%2011.6819%2011.6819%2014.6667%208.00004%2014.6667ZM7.33337%207.33333V11.3333H8.66671V7.33333H7.33337ZM7.33337%204.66667V6H8.66671V4.66667H7.33337Z'%20fill='white'/%3e%3c/g%3e%3c/svg%3e" className="sc-kbdmFF hMkoIx" alt="" />
                                </div>
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dCYwPY"></div>
                                        <div className="sc-deXguC eqWvHz"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$UP</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$UP</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQBT4_o-X_mGs6NcySOzjz54g6Q9qyxalEmvxLDTD0J7RXZa" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQBT...RXZa</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">31 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">July 4 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">August 4 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">UP</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">UP</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dCYwPY"></div>
                                        <div className="sc-deXguC lamzld"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$UP</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$TPX</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQBLTJ3pVTZiN711Kjwuu_-DPMNVPCtxCxYJcyaMw77K3S7I" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQBL...3S7I</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">5 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">June 13 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">June 18 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">UP</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">TPX</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dnBVji"></div>
                                        <div className="sc-deXguC RXOKn"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$NOT</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$up</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQCQ9tbRUf8OobfU-x4OYklMIdk1tDk4CmDMxjZEsWKPHqnO" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQCQ...HqnO</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">2 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">May 21 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">May 23 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">NOT</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">up</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dCYwPY"></div>
                                        <div className="sc-deXguC gjjIPZ"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$UP</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$usdt</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQBk04plpd2eNkCqREyY7XFfSp2Mpwm10FLQcGBq6U5mICCR" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQBk...ICCR</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">3 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">May 21 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">May 24 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">UP</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">usdt</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dCYwPY"></div>
                                        <div className="sc-deXguC hwBuaI"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$UP</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$tong</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQAxN5aREN_WF-He1uKeQsalDxobF3kz2u-5U69x_RAzK3sQ" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQAx...K3sQ</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">3 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">May 21 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">May 24 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">UP</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">tong</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn dCYwPY"></div>
                                        <div className="sc-deXguC gqlYVc"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$Up</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$ton</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQDdDTW59apAnVKO33XAOB1TNVljQoXSS_qd-4QrVjQjCSW3" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQDd...CSW3</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">30 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">March 30 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">April 29 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">Up</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">ton</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn hCOyIZ"></div>
                                        <div className="sc-deXguC gqlYVc"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$Deer</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$ton</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQBOYyOsOZoqiOBFZpzqC0XVhn7kFqLV38veEGyMqhXcf7dn" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQBO...f7dn</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">7 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">March 30 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">April 6 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">Deer</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">ton</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn jqRlnM"></div>
                                        <div className="sc-deXguC gqlYVc"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$Tele</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$ton</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQAeqHebkn0eED26LHGFlFJSYxP2vlvLn7N0-mDoc4GzG8Z6" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQAe...G8Z6</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">7 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">March 30 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">April 6 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">Tele</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">ton</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bJBfJu dgbqsT relative tokenItem">
                                <div className="sc-dGCntx froswN">
                                    <div className="sc-fAGzVM iJnIpc">
                                        <div className="sc-bkSTSn ktgrYH"></div>
                                        <div className="sc-deXguC gqlYVc"></div>
                                    </div>
                                    <h2 className="sc-dQEsWe iSFRlV">
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Stake</div>
                                                <div className="sc-epAKVu gVCvoG">$Tong</div>
                                            </div>
                                        </div>
                                        <div className="sc-dJjagV bhVFxe">to</div>
                                        <div className="sc-imwrwD fEjatR">
                                            <div className="sc-kCMLfs fwZffn">
                                                <div className="sc-dJjagV bhVFxe">Earn</div>
                                                <div className="sc-epAKVu gVCvoG">$ton</div>
                                            </div>
                                        </div>
                                    </h2><a href="https://tonviewer.com/EQA_-86GEiI-kNclPJTN-HiJMjKrJTTM64fu2lPkjEji8EjK" className="sc-iLsJvT bZtAUN"><span color="rgba(255, 255, 255, 0.5)" className="sc-eqUzNf dWgCrn">EQA_...8EjK</span></a></div>
                                <div className="sc-fUBjpT dwZasy">
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Staking Period:</label><span className="sc-bOQUxc bGeFKG">7 days</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Starting Time:</label><span className="sc-bOQUxc bGeFKG">March 30 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Ending Time:</label><span className="sc-bOQUxc bGeFKG">April 6 12:00 PM UTC</span></div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Rewards:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                    <div className="sc-hBtQOi eqRTzI"><label className="sc-hYmYN lnMaOP">Total Stakes:</label><span className="sc-bOQUxc bGeFKG"><span className="sc-iGgVNO fdAToM">Please Connect Wallet</span></span>
                                    </div>
                                </div>
                                <div className="sc-lbJdeI dXDdtq">
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Stake</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-eXrZXP cHbkfL">Tong</span></span></span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">STAKE</button></div>
                                    </div>
                                    <div className="sc-eifsgl bDidaQ"><span className="sc-fKWLGC liFBdw">My Reward</span><span className="sc-kgOKhb IPHdW"><span>0 <span className="sc-gsFSjX bvrujt">ton</span></span>
                                    </span>
                                        <div className="sc-bfhwqR dfpLtg"><button className="sc-cMa-dPg bemqnk">CLAIM</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
            <div role="region" aria-label="Notifications (F8)" style={{ pointerEvents: "none" }}>
                <ol className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:top-0 sm:right-0 sm:bottom-auto sm:flex-col md:max-w-[420px]"></ol>
            </div>
        </div>
    </>
}