import { Deposit, User } from "../../../types";
import TransactionInfo from "./TransactionInfo";

export default function DepositList({ deposits, setUser }: { deposits: Deposit[], setUser?: React.Dispatch<React.SetStateAction<User | null>> }) {
    return <>
        {deposits.filter(deposit => deposit.status === "pending").length > 0 &&
            <>
                <div className="additional-wrap-of-crm">
                    <div className="current-data mt10">
                        <span>Статус:</span>
                        <span className="-yellow-info">Список введенных сумм для депозита ( {deposits.filter(deposit => deposit.status === "pending").length} )</span>
                    </div>
                </div>

                <div className="dinamic-content -cmr-users -other-pages-content">
                    <div className="list-of-users">
                        {deposits.filter(deposit => deposit.status === "pending").map(deposit => <TransactionInfo transaction={deposit} key={deposit.id} setUser={setUser} />)}
                    </div>
                </div>
            </>
        }

        {deposits.filter(deposit => deposit.status === "rejected").length > 0 &&
            <>
                <div className="additional-wrap-of-crm">
                    <div className="current-data mt10">
                        <span>Статус:</span>
                        <span className="-red-info">Фэйк ( {deposits.filter(deposit => deposit.status === "rejected").length} )</span>
                    </div>
                </div>

                <div className="dinamic-content -cmr-users -other-pages-content">
                    <div className="list-of-users">
                        {deposits.filter(deposit => deposit.status === "rejected").map(deposit => <TransactionInfo transaction={deposit} key={deposit.id} />)}
                    </div>
                </div>
            </>
        }

        <div className="additional-wrap-of-crm">
            <div className="current-data mt10">
                <span>Статус:</span>
                <span className="-green-info">Депозит ( {deposits.filter(deposit => deposit.status === "completed").length} )</span>
            </div>
        </div>

        <div className="dinamic-content -cmr-users -other-pages-content">
            <div className="list-of-users">
                {deposits.filter(deposit => deposit.status === "completed").map(deposit => <TransactionInfo transaction={deposit} key={deposit.id} />)}
            </div>
        </div>
    </>
}