import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../../types";
import Input from "../Input";
import DateTime from "../DateTime";
import DepositsController from "../../../api/admin/deposits";

export default function AddDeposit({ setUser }: { setUser: React.Dispatch<React.SetStateAction<User | null>> }) {
    const { id } = useParams();
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState('');

    const addDeposit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await DepositsController.addDeposit(Number(id), date, amount);

        if (response.error) return toast(response.message);

        setUser(response.user);

        toast("Депозит добавлен");
    }, [id, date, amount, setUser]);

    if (!id) return null;

    return <form className="user-item-of-list -full-height" onSubmit={addDeposit}>
        <div className="down-part-of-user-info">
            <div className="right-part-of-secondary-pages w100proc">
                <div className="dark-block-content ">
                    <div className="second-others-inner-header" style={{ marginTop: "0", color: "#fff" }}>
                        Депозит
                    </div>
                    <div className="input-wrap  -valid-input">
                        <div className="header-of-input">
                            Дата и время (DD/MM/YYYY HH:MM)
                        </div>

                        <DateTime date={date} setDate={setDate} />

                        <div className="error-input">
                            Вы допустили ошибку
                        </div>
                    </div>

                    <Input title="Сумма" state={amount} setState={setAmount} placeholder="" />

                    <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Добавить депозит</button>
                </div>
            </div>

        </div>
    </form>
}