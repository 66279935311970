import { NavLink } from "react-router-dom";
import logo_white from "../../assets/img/logo_white.svg";
import tg_app_center from "../../assets/img/tg_app_center.svg";
import { useUser } from "../../context/UserContext";

export default function Footer() {
    const { user } = useUser()

    return <footer className="sc-dwamxw esypfR">
        <div className="sc-BQMOd cqEFeB">
            <div className="sc-epqopy diCJg">
                <img src={logo_white} className="sc-bvgOGf lekSXK" alt="" />
                <p className="sc-kUdmUV XXnQi">TonUP is an innovative platform built on the TON Blockchain that aims to revolutionize the way projects are launched and supported. We provide a comprehensive ecosystem for token launches, community participation, and project growth.<br /><br />TonUP
                    is on a mission to discover and nurture the most promising projects out there, bringing exciting opportunities to the wider community. With the unwavering support of TON Foundation and Foresight X, we are dedicated to empowering
                    assets with remarkable potential on The Open Network.</p>
                <img src={tg_app_center} className="sc-cyRdfs fZCSgQ" alt="" />
            </div>
            <div className="sc-hgRQsa hzvYAK">
                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Features</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <NavLink className={({ isActive, isPending }) =>
                            isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                        } to="/" target="_self">Launchpad</NavLink>
                        <NavLink className={({ isActive, isPending }) =>
                            isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                        } to="/staking" target="_self">Staking</NavLink>
                        <a className="sc-faUjVh gtKnPH" href="https://app.ston.fi/pools/EQBWfg7p9XCqGOfLPRRx5eGaFouu8fLSycnXbiqXPGV_f3iJ" target="_blank" rel="noreferrer">Farming</a></div>
                </div>
                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz"><span className="sc-dcJtft dBLeMy">$TONUP</span>&nbsp;Token</h3>
                    <div className="sc-dUYKzm GhXxx"><a className="sc-faUjVh gtKnPH" href="https://tonviewer.com/EQCvaf0JMrv6BOvPpAgee08uQM_uRpUd__fhA7Nm8twzvbE_" target="_blank" rel="noreferrer">Tonviewer</a><a className="sc-faUjVh gtKnPH" href="https://app.ston.fi/swap?chartVisible=false&amp;ft=TON&amp;tt=UP" target="_blank" rel="noreferrer">STON.fi</a><a className="sc-faUjVh gtKnPH" href="https://dedust.io/swap/EQCvaf0JMrv6BOvPpAgee08uQM_uRpUd__fhA7Nm8twzvbE_" target="_blank" rel="noreferrer">DeDust</a><a className="sc-faUjVh gtKnPH" href="https://partner.bitget.fit/bg/UPTOKEN" target="_blank" rel="noreferrer">Bitget</a><a className="sc-faUjVh gtKnPH" href="https://www.cointr.pro/en-us/spot/TONUPUSDT" target="_blank" rel="noreferrer">CoinTR</a><a className="sc-faUjVh gtKnPH" href="https://promote.mexc.com/a/tonup" target="_blank" rel="noreferrer">MEXC</a>
                        <a className="sc-faUjVh gtKnPH" href="https://www.gate.io/trade/UP_USDT?ref=VVkRU1Bb&amp;ref_type=106" target="_blank" rel="noreferrer">Gate.io</a><a className="sc-faUjVh gtKnPH" href="https://bingx.com/en-us/spot/TONUPUSDT/" target="_blank" rel="noreferrer">BingX</a><a className="sc-faUjVh gtKnPH" href="https://www.bit.com/spot?pair=UP-USDT" target="_blank" rel="noreferrer">BIT</a><a className="sc-faUjVh gtKnPH" href="https://www.xt.com/en/trade/tonup_usdt" target="_blank" rel="noreferrer">XT.com</a></div>
                </div>
                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Wallet</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <NavLink className={({ isActive, isPending }) =>
                            isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                        } to="/terms" target="_blank" rel="noreferrer">Terms of Service</NavLink>
                        {!user && <>
                            <NavLink className={({ isActive, isPending }) =>
                                isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                            } to="/register">Register</NavLink>
                            <NavLink className={({ isActive, isPending }) =>
                                isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                            } to="/login">Login</NavLink>
                        </>}

                        <NavLink className={({ isActive, isPending }) =>
                            isPending ? "sc-faUjVh gtKnPH" : isActive ? "sc-faUjVh fBCktP" : "sc-faUjVh gtKnPH"
                        } to="/dashboard">Wallet</NavLink>
                    </div>
                </div>

            </div>
        </div>
        <div className="sc-cXPBhi eddhiW">
            <div className="sc-epqopy diCJg"><span className="sc-bhqpXc cqJFOf">Copyright© 2024 TonUP</span></div>
        </div>
    </footer>
}