import { Link } from "react-router-dom"
import tg_app_center from "../../../assets/img/tg_app_center.svg"

export default function Footer() {
    return <footer className="sc-dwamxw esypfR">
        <div className="sc-BQMOd cqEFeB">
            <div className="sc-epqopy diCJg">
                <p className="sc-kUdmUV XXnQi">TonUP is an innovative platform built on the TON Blockchain that aims to revolutionize the way projects are launched and supported. We provide a comprehensive ecosystem for token launches, community participation, and project growth.<br /><br />TonUP
                    is on a mission to discover and nurture the most promising projects out there, bringing exciting opportunities to the wider community. With the unwavering support of TON Foundation and Foresight X, we are dedicated to empowering
                    assets with remarkable potential on The Open Network.
                </p>
                <img src={tg_app_center} className="sc-cyRdfs fZCSgQ" alt="" />
            </div>
            <div className="sc-hgRQsa hzvYAK">

                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Wallet</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <Link className="sc-faUjVh gtKnPH" to="/terms" target="_blank">Terms of Service</Link>
                    </div>
                </div>

            </div>
        </div>
        <div className="sc-cXPBhi eddhiW">
            <div className="sc-epqopy diCJg"><span className="sc-bhqpXc cqJFOf">Copyright© 2024 TonUP</span></div>
        </div>
    </footer>
}