import http from ".";
import { ErrorResponseType, UserResponseType } from "../../types";

const addWithdrawal = async (id: number, date: string, amount: string, address: string, memo: string, status: "completed" | "rejectedDefault" | "rejectedCustom", msg?: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/admin/users/${id}/withdrawal`, { date, amount, address, memo, status, msg });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const handlePendingWithdrawal = async (id: number, status: "completed" | "rejectedDefault" | "rejectedCustom", msg?: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/withdrawal/${id}`, { status, msg });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const WithdrawalsController = {
    addWithdrawal,
    handlePendingWithdrawal
}

export default WithdrawalsController;