import http from "./index";
import { ErrorResponseType, UserResponseType, UsersResponseType } from "../../types";

const getUsers = async (): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/users/`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const banUser = async (id: number): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/ban/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const unbanUser = async (id: number): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/unban/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getUser = async (id: number): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/users/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const updateUser = async (id: number, firstname: string, lastname: string, email: string, phone: string, password: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/users/${id}`, {
            firstname,
            lastname,
            email,
            phone,
            password
        });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const updateNote = async (id: number, note: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/users/${id}/note`, { note });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const UsersContoller = {
    getUsers,
    banUser,
    unbanUser,
    getUser,
    updateUser,
    updateNote,
}

export default UsersContoller;